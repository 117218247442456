import axios from "axios";
import Cookies from "js-cookie";

const ModuleName = "Edogov_General_RequestManager";
// const URL = Cookies.get('_APIBaseURL') + '/generalrequest';
const URL = "https://edogoverp.com/newdashboard";
const URLDOC = Cookies.get("_APIBaseURL");

export let Access_token = Cookies.get("_AppToken") || Cookies.get("token");

const Http = axios.create({
  baseURL: URL,
  // headers,
});

Http.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = Access_token
      ? `Bearer ${Access_token}`
      : "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default Http;
