/* eslint-disable import/no-anonymous-default-export */
import React from "react";

import { RiBarChartFill, RiUserSettingsLine } from "react-icons/ri";
import { BiEnvelope } from "react-icons/bi";
import { BiLogOut } from "react-icons/bi";
import { CgUserList } from "react-icons/cg";

export default [
  {
    title: "Dashboard",
    href: "/portal-dashboard",
    icon: <RiBarChartFill className="icon" />,
  },
  {
    title: "Settings",
    href: "/portal-dashboard/settings",
    icon: <BiEnvelope className="icon" />,
  },
  { title: "Log Out", href: "/", icon: <BiLogOut className="icon" /> },
];
