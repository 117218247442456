/* eslint no-use-before-define: 0 */
import React, { useEffect, useState } from "react";
import {
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Radio,
  DatePicker,
  Modal,
} from "antd";
import moment from "moment";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import Page6Cards from "../layouts/Page6Cards";
import SearchInput from "../inputs/SearchInput";
import Greeting from "../layouts/Greeting";
import ServiceWidget from "../layouts/ServiceWidget";
import {
  DashboardMainStatistics,
  DashboardMdaRequestCount,
  RequestLogs,
  UpdateRequestItem,
  GetModuleLink,
} from "../../utility/fetch";
import Notification from "../../utility/notification";
import { moduleNameData } from "../columns/requestLogColumn";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const Dashboard = ({ history }) => {
  const [title, setTitle] = useState("");
  const [placement, SetPlacement] = React.useState("Days");
  const [receivedMemos, setReceivedMemos] = useState(0);
  const [treateddMemos, setTreatedMemos] = useState(0);
  const [pendingMemos, setPendingMemos] = useState(0);
  const [approvedMemos, setApprovedMemos] = useState(0);
  const [rejectedMemos, setRejectedMemos] = useState(0);
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [mdaRequests, setMdaRequests] = useState(0);
  const [requestLogs, setRequestLogs] = useState();
  const [filteredRequestLogs, setFilteredRequestLogs] = useState([])
  const [allLogs, setAllLogs] = useState();
  const [loading, setLoading] = useState(false);
  const [singleRequest, setSingleRequest] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [details, setDetails] = useState("");
  const [activeMemo, setActiveMemo] = useState("");

  const placementChange = (e) => {
    SetPlacement(e.target.value);
  };


  useEffect(()=>{

      setFilteredRequestLogs(requestLogs?.filter((data) =>{
        
        if(searchText?.toLocaleLowerCase() == null) return data;
                else if (
                  data?.mdaName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                  data?.moduleName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                  data?.requestDetails?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                  data?.requestInitiator?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                  data?.status?.toLowerCase()?.includes(searchText?.toLowerCase())
                ){
                  return data;
                }
      }))
  }, [requestLogs, searchText])



  useEffect(()=>{
   
    setFilteredRequestLogs(requestLogs?.filter((data)=>{
      return moment(data?.lastUpdated).isAfter(moment(startDate)) && moment(data?.lastUpdated).isBefore(endDate)
    }))
  }, [startDate, endDate])

  

const handleMdaDateChange = (value, sting) =>{
  setStartDate(new Date(moment(value[0])._d))
  setEndDate(moment(value[1])._d)
}



  const fetchRequestLogs = async () => {
    setLoading(true);
    try {
      const result = await RequestLogs();
      if (result?.data?.statusCode === "00") {
        
        setRequestLogs(result?.data?.result);
        setAllLogs(result?.data?.result);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const fetchDashboardMdaRequestCount = async () => {
    setLoading(true);
    try {
      const result = await DashboardMdaRequestCount();
      if (result?.data?.statusCode === "00") {
        setMdaRequests(result?.data?.result);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const fetchDashboardMainStatistics = async () => {
    setLoading(true);
    try {
      const result = await DashboardMainStatistics();
      if (result?.status === 200) {
        const memo = result?.data;
        setReceivedMemos(memo?.receivedMemos);
        setTreatedMemos(memo?.treateddMemos);
        setPendingMemos(memo?.pendingMemos);
        setApprovedMemos(memo?.approvedMemos);
        setRejectedMemos(memo?.rejectedMemos);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const updateRequestItemStatus = async (payload) => {
    setLoading(true);
    try {
      const result = await UpdateRequestItem([payload]);
      if (result?.data?.statusCode == "00") {
        Notification({
          title: `${payload?.status} Successful!`,
          message: `${result?.data?.apiMessage}`,
          type: "success",
        });
        fetchRequestLogs();
        fetchDashboardMainStatistics();
        fetchDashboardMdaRequestCount();
      } else {
        Notification({
          title: "Error!",
          message: `${result?.data?.apiMessage}`,
          type: "danger",
        });
      }
    } catch (ex) {
      Notification({
        title: "Error!",
        message: `${ex.response?.data?.apiMessage}`,
        type: "danger",
      });
    }
    setLoading(false);
  };

  const RedirectToModuleLink = async (payload) => {
    setLoading(true);
    try {
      const result = await GetModuleLink([payload]);
      if (result?.data?.statusCode == "00") {
        window.location.href = `${
          result?.data?.url
        }${result?.data?.address.replace("https://edogoverp.com?", "?")}`;
      } else {
        Notification({
          title: "Error!",
          message: `${result?.data?.apiMessage}`,
          type: "danger",
        });
      }
    } catch (ex) {
      Notification({
        title: "Error!",
        message: `${ex.response?.data?.apiMessage}`,
        type: "danger",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDashboardMainStatistics();
    fetchDashboardMdaRequestCount();
    fetchRequestLogs();
    setActiveMemo("");
  }, []);

  const filterLogs = (activeMemo) => {
    setActiveMemo(activeMemo);
    if (activeMemo.toLowerCase() != "received") {
      const result = allLogs.filter(
        (m) => m.status.toLowerCase() == activeMemo.toLowerCase()
      );
      setRequestLogs(result);
    } else {
      setRequestLogs(allLogs);
    }
  };

  const ipsum =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum";

  const { RangePicker } = DatePicker;

  const dateFormat = "YYYY-MM-DD";

  const menu = (record) => (
    <Menu>
      {record.status.toLowerCase() == "pending" && (
        <>
          <Menu.Item
            onClick={() => {
              record.status = "7";
              updateRequestItemStatus(record);
            }}
          >
            <a rel="noopener noreferrer" href="#">
              Approve
            </a>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              record.status = "8";
              updateRequestItemStatus(record);
            }}
          >
            <a rel="noopener noreferrer" href="#">
              Decline
            </a>
          </Menu.Item>
        </>
      )}

      <Menu.Item
        onClick={() => {
          record.status = "0";
          RedirectToModuleLink(record);
        }}
      >
        <a rel="noopener noreferrer" href="#">
          View
        </a>
      </Menu.Item>
    </Menu>
  );

  const mdaRequestsColumns = [
    {
      title: (
        <div>
          {" "}
          <strong className="family m-r-10">Request Agency/MDA</strong>
          <button className="rounded family">
            <strong>Show All</strong>
          </button>
        </div>
      ),
      dataIndex: "mdaName",
      //   width: '55%',
      editable: false,
    },
    {
      title: <strong className="family">Received</strong>,
      dataIndex: "received",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Treated</strong>,
      dataIndex: "treated",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Pending</strong>,
      dataIndex: "pending",
      width: "10%",
    },
    {
      title: <strong className="family">Approved</strong>,
      dataIndex: "approved",
      width: "10%",
    },
  ];

  const displayTruncatedDetails = (details, number) => {
    if (details.length > number) {
      return `${details.substring(0, 40)}...`;
    } else {
      return details;
    }
  };

  const requestLogsColums = [
    {
      title: <strong className="family">Request Title</strong>,
      dataIndex: "requestType",
      width: "15%",
      editable: false,
    },
    {
      title: <strong className="family">Request Initiator</strong>,
      dataIndex: "requestInitiator",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Module</strong>,
      dataIndex: "moduleName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Request Agency/MDA</strong>,
      dataIndex: "mdaName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Request Details</strong>,
      // dataIndex: "requestDetails",
      render: (item) => (
        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            setDetails(item?.requestDetails);
            setModalVisible(true);
          }}
        >
          {item?.requestDetails
            ? displayTruncatedDetails(item?.requestDetails, 20)
            : ""}
        </p>
      ),
      width: "20%",
      align: "center",
    },
    {
      title: <strong className="family">Last Updated</strong>,
      dataIndex: "lastUpdated",
      width: "5%",
    },
    {
      title: <strong className="family">Status</strong>,
      dataIndex: "status",
      width: "5%",
    },
    {
      title: <strong className="family">Action</strong>,
      dataIndex: "action",
      width: "5%",
      render: (text, record) => (
        <Space direction="vertical">
          <Space wrap>
            <Dropdown
              overlay={menu(record)}
              trigger={["click"]}
              placement="bottom"
            >
              <Button style={{ backgroundColor: "#1C811C", color: "white" }}>
                Select
              </Button>
            </Dropdown>
          </Space>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Dashboard";
    setTitle("Dashboard");
  }, []);

  

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <Greeting />
          <div className="flex space-between w-100 m-b-50">
            <Page6Cards
              cardTitle="Recieved Requests"
              count={receivedMemos}
              onClick={() => filterLogs("Received")}
              isActive={activeMemo == "Received"}
            />
            <Page6Cards
              cardTitle="Treated Requests"
              count={treateddMemos}
              onClick={() => filterLogs("Treated")}
              isActive={activeMemo == "Treated"}
            />
            <Page6Cards
              cardTitle="Pending Requests"
              count={pendingMemos}
              onClick={() => filterLogs("Pending")}
              isActive={activeMemo == "Pending"}
            />
            <Page6Cards
              cardTitle="Approved Requests"
              count={approvedMemos}
              onClick={() => filterLogs("Approved")}
              isActive={activeMemo == "Approved"}
            />
            <Page6Cards
              cardTitle="Rejected Requests"
              count={rejectedMemos}
              onClick={() => filterLogs("Declined")}
              isActive={activeMemo == "Declined"}
            />
          </div>

          <div style={{ width: "100%" }}>
            <div className="flex space-between m-b-20 align-end ">
              <strong className="family m-l-20">5 of 17 MDAs</strong>
              {/* <div>
                <Radio.Group
                  value={placement}
                  onChange={placementChange}
                  className="m-r-10"
                >
                  <Radio.Button value="Days">Days</Radio.Button>
                  <Radio.Button value="Weeks">Week</Radio.Button>
                  <Radio.Button value="Months">Months</Radio.Button>
                </Radio.Group>

                {placement === "Months" ? (
                  <RangePicker
                    picker="month"
                    defaultValue={[
                      moment("2015-06-06", dateFormat),
                      moment("2015-06-06", dateFormat),
                    ]}
                    onChange={onChangeDate}
                  />
                ) : placement === "Weeks" ? (
                  <RangePicker
                    picker="week"
                    defaultValue={[
                      moment("2015-06-06", dateFormat),
                      moment("2015-06-06", dateFormat),
                    ]}
                    onChange={onChangeDate}
                  />
                ) : (
                  <RangePicker
                    defaultValue={[
                      moment("2015-06-06", dateFormat),
                      moment("2015-06-06", dateFormat),
                    ]}
                    onChange={onChangeDate}
                  />
                )}
              </div> */}
            </div>
            <Table
              bordered
              dataSource={mdaRequests}
              columns={mdaRequestsColumns}
              rowClassName="editable-row"
            />
          </div>

          <div className="flex space-between m-b-20">
            <div>
              <Radio.Group
                value={placement}
                onChange={placementChange}
                className="m-r-10"
              >
                <Radio.Button value="Days">Days</Radio.Button>
                <Radio.Button value="Weeks">Week</Radio.Button>
                <Radio.Button value="Months">Months</Radio.Button>
              </Radio.Group>

              {placement === "Months" ? (
                <RangePicker
                  picker="month"
                  defaultValue={[
                    moment("2022-01-01", dateFormat),
                    moment("2022-01-01", dateFormat),
                  ]}
                  onChange={handleMdaDateChange}
                />
              ) : placement === "Weeks" ? (
                <RangePicker
                  picker="week"
                  defaultValue={[
                    moment("2022-01-01", dateFormat),
                    moment("2022-01-01", dateFormat),
                  ]}
                  onChange={handleMdaDateChange}
                />
              ) : (
                <RangePicker
                  defaultValue={[
                    moment("2022-01-01", dateFormat),
                    moment("2022-01-01", dateFormat),
                  ]}
                  onChange={handleMdaDateChange}
                />
              )}
            </div>
            <SearchInput value = {searchText} onChange = {e => setSearchText(e.target.value)} />
          </div>
          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">Request Log</strong>
          </div>
          <div className="w-100">
            <Table
              bordered
              dataSource={filteredRequestLogs}
              columns={requestLogsColums}
              rowClassName="editable-row"
            />
          </div>
          <ServiceWidget />
        </>
      )}

      <Modal
        title="Request Details"
        centered
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      >
        <p>{details}</p>
      </Modal>
    </div>
  );
};

export default Dashboard;
