import React from "react";
import { Switch, Route } from "react-router";
import { pure } from "recompose";

import ProtectedRoute from "./ProtectedRoute";

import Dashboard from "../components/pages/Dashboard";
import Settings from "../components/pages/Settings";
import Profile from "../components/pages/Profile";

export default pure(() => (
  <Switch>
    <ProtectedRoute path="/portal-dashboard/" exact component={Dashboard} />
    <ProtectedRoute
      path="/portal-dashboard/profile"
      exact
      component={Profile}
    />
    <ProtectedRoute
      path="/portal-dashboard/settings"
      exact
      component={Settings}
    />

    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
