import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import Greeting from "../layouts/Greeting";
import Page6Cards from "../layouts/Page6Cards";
import { Table, Checkbox } from "antd";
import {
  DashboardModuleCount,
  EmployeeModules,
  UpdateEmployeeModules,
} from "../../utility/fetch";
import Notification from "../../utility/notification";

const Settings = () => {
  const [moduleCount, setModuleCount] = useState([]);
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const settingsData = [
    {
      name: "Facility Management",
      receiveMemo: false,
      notification: false,
    },
    {
      name: "Admin & Store Management",
      receiveMemo: false,
      notification: false,
    },
    {
      name: "SUBEB",
      receiveMemo: false,
      notification: false,
    },
    {
      name: "Budget Management",
      receiveMemo: false,
      notification: false,
    },
    {
      name: "Mail Manager",
      receiveMemo: false,
      notification: false,
    },
  ];

  const settingsColumns = [
    {
      title: (
        <div>
          <strong className="family m-r-10">Module</strong>
        </div>
      ),
      dataIndex: "moduleName",
      editable: false,
    },
    {
      title: (
        <div>
          {" "}
          <strong className="family m-r-10">Receive Memo</strong>
        </div>
      ),
      editable: false,
      width: "10%",
      render: (record) => (
        <Checkbox
          onClick={() => sunscribeEmployeeToModule(record)}
          checked={record?.subscribe == 1}
        />
      ),
    },
    {
      title: (
        <div>
          {" "}
          <strong className="family m-r-10">Notification</strong>
        </div>
      ),
      dataIndex: "notification",
      editable: false,
      width: "10%",
      render: (text, record) => {
        return <Checkbox />;
      },
    },
  ];

  const sunscribeEmployeeToModule = async (data) => {
    const payload = {
      empID: data?.empId,
      moduleID: data?.empModuleId,
      moduleName: data?.moduleName,
      subscribe: data?.subscribe == 1 ? 0 : 1,
    };

    const action = data?.subscribe == 1 ? "Unsubscribe" : "Subscribe";
    const result = await UpdateEmployeeModules([payload]);

    if (result?.data?.statusCode == "00") {
      Notification({
        title: `${action} Successful!`,
        message: `${result?.data?.apiMessage}`,
        type: "success",
      });
      fetchEmployeeModules();
      fetchDashboardModuleCount();
    } else {
      Notification({
        title: "Error!",
        message: `${result?.data?.apiMessage}`,
        type: "danger",
      });
    }
  };

  const fetchDashboardModuleCount = async () => {
    setLoading(true);
    try {
      const result = await DashboardModuleCount();
      if (result?.data?.statusCode === "00") {
        setModuleCount(result?.data?.result);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const fetchEmployeeModules = async () => {
    setLoading(true);
    try {
      const result = await EmployeeModules();
      if (result?.data?.statusCode === "00") {
        setModules(result?.data?.result);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDashboardModuleCount();
    fetchEmployeeModules();
  }, []);

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <Greeting />
          <div className="flex space-between w-50 m-b-50">
            {moduleCount.map((record, index) => (
              <Page6Cards
                key={record?.id}
                cardTitle={record?.description}
                count={record?.count}
              />
            ))}
          </div>

          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">EDSG Module Manager</strong>
          </div>

          <div className="w-100">
            <Table
              bordered
              dataSource={modules}
              columns={settingsColumns}
              rowClassName="editable-row"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
